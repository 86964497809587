import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaSpinner, FaSave, FaPlus, FaPaperclip } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { useDropzone } from 'react-dropzone';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { symbol } from 'prop-types';
import moment from 'moment';
import MentionInput from 'ui-component/MentionInput/MentionInput';

import MentionDisplay from 'ui-component/MentionInput/MentionDisplay';
import { set } from 'draft-js/lib/EditorState';
import ReactSelect from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';


const PaymentModal = ({ showModal, details, item, total, handleClose, handleSaveAndClose }) => {
    const [formInfo, setFormInfo] = useState({
        estimationId: '',
    });
    const [disableStatus, setDisablestatus] = useState(false);
    const [hasAttachment, setHasAttachment] = useState(false);
    const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
    const [remainingBalance, setRemainingBalance] = useState(0);
    const [formDetails, setFormDetails] = useState(null);
    const [formAttachment, setFormAttachment] = useState(null);
    const [defaultPaymentType, setDefaultPaymentType] = useState('bank_transfer');
    const [defaultCurrency, setDefaultCurrency] = useState('USD($)');
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [uploadedFileSize, setUploadedFileSize] = useState('');
    const [convertedTotalOpen, setConvertedTotalOpen] = useState(0);
    const [remaningBalance, setRemaningBalance] = useState(0);
    const [uploadedPaymentAttachment, setUploadedPaymentAttachment] = useState(null);
    const textareaRef = useRef(null);
	const [inputFocus, setInputFocus] = useState(false);
	const [noteInputValue, setNoteInputValue] = useState('');
	const [noteInputPlain, setNoteInputPlain] = useState('');
	const [noteMentions, setNoteMentions] = useState([]);
    const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
    const [vendorCreditLists, setVendorCreditLists] = useState([]);
    const [selectedVendorCredit, setSelectedVendorCredit] = useState(null);
    
    const handleValue = (val) => {
        setFormInfo({
            ...formInfo,
            estimationId: val?.id
        });
        setFormInfo({ estimationId: val?.value });
    };
    const formData = new FormData();

    const paymentTypes = [
        { label: 'Bank Transfer', value: 'bank_transfer' },
        { label: 'Bank Cheque', value: 'bank_cheque' },
        { label: 'Cash', value: 'cash' },
        { label: 'Credit Card', value: 'credit_card' },
        { label: 'Vendor Credit', value: 'vendor_credit' },
    ];

    const [paymentDetails, setPaymentDetails] = useState({
        currency: details?.currency,
        paymentType: 'bank_transfer',
        paymentDate: currentDate,
        amount: 0,
        attachment: null,
        note: '',
        // open: 0,
        // paid: 0,
        // type: index,
    });


    const currencies = [
        { label: 'USD($)', value: 'usd', symbol: '$' },
        { label: 'EUR(€)', value: 'eur', symbol: '€' },
        { label: 'AED(AED)', value: 'aed', symbol: 'AED' },
    ];   

    const initialize = async () => {

    }


    useEffect(() => {
        setDefaultCurrency(currencies?.filter((item) => item.value === paymentDetails?.currency)[0].label);
    }, []);

    const handleChangeCurrency = (currency) => {
        setConvertedTotalOpen(details?.converted_currency_open_balance[paymentDetails?.currency])
        setDefaultCurrency(currency.label);
        setPaymentDetails({ ...paymentDetails, currency: currency?.value });
        setRemaningBalance((details?.converted_currency_open_balance[currency?.value] - details?.converted_currency_paid_amount[currency?.value]) - paymentDetails?.amount);
        // setSelectedCurrency(currency?.value);
    }

    useEffect(() => {
        // setRemainingBalance(total);
        setPaymentDetails({ ...paymentDetails, paymentDate: moment().format('YYYY-MM-DD') });
        setRemaningBalance((details?.converted_currency_open_balance[paymentDetails?.currency] - details?.converted_currency_paid_amount[paymentDetails?.currency]) - paymentDetails?.amount);
    }, [paymentDetails?.amount]);

    const disablepopup = () => {
        handleClose();
    };


    const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png'];
    const validateFileExtension = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };

    const [paymentAttachment, setPaymentAttachment] = useState('');
	const [vatValidationAlert, setVatvalidationlert] = useState();
    
	const [paymentData, setPaymentData] = useState({
		purchase_invoice_id: details?.id,
		attachment: '',
		filename: '',
    });
    const [attachment, setAttachment] = useState([]);
    
	const onDrop = useCallback((acceptedFiles) => {
		console.log('onDrop triggered', acceptedFiles);
		// if (validateFileExtensionforLogo(acceptedFiles[0].name)) {
		// 	setData((prevData) => ({ ...prevData, companyLogo: acceptedFiles[0] }));
		// 	setLogo(acceptedFiles[0].name);
		// } else {
			toast.error('Invalid file extension. Please select a JPEG, JPG, PNG, or PDF file.');
		// }
	}, []);

    const handleSubmitForm = () => { 
        if (paymentDetails?.paymentType == '') {
            toast.error('Payment type is required');
            return false;
        } else if (paymentDetails?.paymentDate == null) {
            toast.error('Payment date is required');
            return false;
        } else if (paymentDetails?.amount == '' || parseFloat(paymentDetails?.amount) < 0) {
            toast.error('Payment amount is required');
            return false;
        }
        
        if (paymentDetails?.paymentType === 'vendor_credit') {
            if (!selectedVendorCredit?.id) {
                toast.error('Please select a vendor');
                return false;
            }
        }
        formData.append('purchase_invoice_id', details?.id);
        formData.append('currency', paymentDetails?.currency);
        formData.append('payment_type', paymentDetails?.paymentType);
        formData.append('payment_date', paymentDetails?.paymentDate);
        formData.append('amount', paymentDetails?.amount);
        formData.append('note', paymentDetails?.note);
        formData.append('attachment', uploadedPaymentAttachment);
        if (selectedVendorCredit?.id) {
            formData.append('vendor_credit_id', selectedVendorCredit?.id);
        }
        // console.log('formData', formData)
        toast.promise(
            api
                .addPurchaseOrderPayment(details?.id, formData)
                .then(
                    (res) => {
                        if (res.status) {
                            handleSaveAndClose();
                        }

                        return res?.status ? res : null;
                    },
                    () => {}
                ),
            {
                loading: 'Saving payment...',
                success: (data) => `${data.message}`,
                error: <b>Failed to save payment</b>
            }
        );
    }


    const handleChangeAmount = (value) => {
        setPaymentDetails({ ...paymentDetails, amount: value });
        setRemaningBalance((details?.converted_currency_open_balance[paymentDetails?.currency] - details?.converted_currency_paid_amount[paymentDetails?.currency]) - value);
    }

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: '.pdf, .jpeg, .jpg, .png',
		multiple: false
    });
    

    const uploadAttachment = async (attachment) => {
        
        setUploadedFileName(attachment.name);
        
        setUploadedPaymentAttachment(attachment);
        let sizeStr = "";
        const sizeInKB = attachment.size / 1024;
        if (sizeInKB >= 1024) {
            const sizeInMB = sizeInKB / 1024;
            sizeStr = `${sizeInMB.toFixed(2)} MB`;
        } else {
            sizeStr = `${sizeInKB.toFixed(2)} KB`;
        }

        

        setUploadedFileSize(sizeStr);
        try {
            setHasAttachment(true)
            setIsAttachmentLoading(true)
            setTimeout(() => {
                setIsAttachmentLoading(false)
            }, 2000);
            
            
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file');
        }
    };
    
	const onPaymentDrop = useCallback((acceptedFiles) => {
		console.log('onPaymentDrop triggered', acceptedFiles);

        if (validateFileExtension(acceptedFiles[0].name)) {
            uploadAttachment(acceptedFiles[0]);
			setPaymentData((prevData) => ({ ...prevData, attachment: acceptedFiles[0] }));
			setPaymentAttachment(acceptedFiles[0].name);
		} else {
			toast.error('Invalid file extension. Please select a PDF, JPEG, JPG, or PNG file.');
		}
	}, []);
	const { getRootProps: getPaymentRootProps, getInputProps: getPaymentInputProps } = useDropzone({ onDrop: onPaymentDrop });


    const handleRemoveAttachment = async () => {
        setUploadedFileName('')
        setUploadedFileSize('')
        setHasAttachment(false)
        setUploadedPaymentAttachment(null)
    }
	const [clearInput, setClearInput] = useState(false);

	const clearInputField = () => {
		setClearInput(true);
		setTimeout(() => {
			setClearInput(false);
		}, 1);
	};

    const handleCreditChange = (e) => {
        setSelectedVendorCredit(e);

        setPaymentDetails({ ...paymentDetails, amount: e?.amount, currency: e?.currency });
    }


    useEffect(() => {
        // console.log('details', details)
		api.getSelectOptionsListWithId('get_open_vendor_credits_by_vendor', details?.supplier?.id).then((res) => {
            if (res?.status) {
                const tempList = res.data.map((item) => ({
                    ...item,
                    label: `${item.option_label_name}`,
                    value: item.id,
                }));
                setVendorCreditLists(tempList);

            }
            // const tempList = res.data.map((item) => ({
			// 	...item,
			// 	label: `${item.option_product_name}`,
			// 	value: item.id,
			// }));
			// setModels(tempList);
			// const value = res.data;
			// const valueArray = value.map((item) => ({ label: item.purchase_order_id, value: item.id, ...item }));
            // setPurchaseOrders(valueArray);
			// setSelectLoading((oldData) => ({
			// 	...oldData,
			// 	purchaseOrder: false
			// }));
		});
    }, [])

    // useEffect(() => {
    //     setRemaningBalance((details?.converted_currency_open_balance[paymentDetails?.currency] - details?.converted_currency_paid_amount[paymentDetails?.currency]) - paymentDetails?.amount);
    // }, [paymentDetails?.amount])
    return (
        <>
            <Modal show={showModal} onHide={disablepopup} centered size="md">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-center">
                                            <h2 className="h4 mb-0 font-weight-bold">Register payment</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '15px' }}>
                                    <div className="col-12 text-center">
                                        You are going to settle a payment for <span className='font500'>{details?.purchase_order_id}</span>
                                        <br />with an amount of
                                        <span className='text-primary font500'>
                                            {
                                                paymentDetails?.currency === 'usd' ? ' $ '
                                                : paymentDetails?.currency === 'eur' ? ' € '
                                                : paymentDetails?.currency === 'aed' ? ' AED '
                                                : paymentDetails?.currency
                                            } {' '}
                                            { parseFloat(details?.converted_currency_open_balance[paymentDetails?.currency] - details?.converted_currency_paid_amount[paymentDetails?.currency]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }
                                        </span>.
                                    </div>
                                </div>
                                {/* <button className="btn modal-close-icon" onClick={handleClose}>
                                    <svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                                        <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                                    </svg>
                                </button> */}
                            </div>
                            <div className="row" style={{ marginTop: '15px' }}>

                                <div className="form-group col-md-6">
                                    <label htmlFor="paymentType">Payment Type</label>

                                
                                    <Select
                                        menuPosition={'fixed'}
                                        options={paymentTypes}
                                        value={paymentTypes?.find((y) => y?.value == paymentDetails?.paymentType)}
                                        className="rs-container"
                                        classNamePrefix="rs"
                                        placeholder="Select or type..."
                                        onChange={(e) => {
                                            setPaymentDetails({ ...paymentDetails, paymentType: e?.value });
                                            if (e?.value !== 'vendor_credit') {
                                                setSelectedVendorCredit(null)
                                            }
                                            // setSelectedPaymentType(e?.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="paymentAmount">Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="paymentDate"
                                        value={paymentDetails?.paymentDate || ''}
                                        onChange={(e) => { 
                                            setPaymentDetails({ ...paymentDetails, paymentDate: e?.target?.value });
                                        }}
                                    />
                                </div>
                                {
                                    paymentDetails?.paymentType === 'vendor_credit' && (
                                        <>
                                            <div className="form-group col-12">
                                                <label htmlFor="paymentAmount">
                                                    Available Credits
                                                </label>
            
                                                <ReactSelect
                                                    className="rs-container"
                                                    classNamePrefix="rs"
                                                    options={[...vendorCreditLists]?.map((m) => ({
                                                        ...m,
                                                        optionAttribute: m.attribute,
                                                    }))}
                                                    // value={models?.find((v) => v?.value == item.model)}
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    placeholder="Select or type..."
                                                    formatOptionLabel={({ label, optionAttribute }) => (
                                                        <div dangerouslySetInnerHTML={{ __html: label }}></div>
                                                    )}
                                                    onChange={(e) => {
                                                        handleCreditChange(e);
                                                    }}
                                                    styles={{
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            // width: '800px', // Adjust the width as needed
                                                            // marginLeft: '-73px',
                                                            // height: '370px', // Set the maximum height of the dropdown box
                                                            overflowY: 'auto', // Enable vertical scrolling
                                                        }),
                                                        option: (provided, { selectProps, data }) => {
                                                            // Find the index of the current option
                                                            const index = selectProps.options.findIndex(option => option.value === data.value);
                                                            return {
                                                            ...provided,
                                                            marginBottom: '5px', // Existing space between options
                                                            marginTop: index === 0 ? '10px' : 0, // Add more spacing on top of the first option
                                                            };
                                                        },
                                                        // option: (provided) => ({
                                                        //   ...provided,
                                                        // 	marginBottom: '5px', // Add space between options
                                                            
                                                        // }),
                                                        // Additional custom styles can be added here
                                                    }}
                                                />
            
                                            </div>
                                        </>
                                    )
                                }
                                <div className="form-group col-md-6">
                                    <label htmlFor="paymentAmount">
                                        Amount 
                                    </label>
                                    <div className="custom-input-group payment-input-group">
                                        {
                                            paymentDetails?.paymentType === 'vendor_credit' ? (
                                                <>
                                        
                                                    <div className=" d-flex input-group-2-form input-group-2-form-currency">
            
                                                        <input
                                                            type="text"
                                                            className="form-control left-input"
                                                            id="inputName"
                                                            value={selectedVendorCredit?.currency_label}
                                                            disabled={true}
                                                            style={{ width: '75px' }}
                                                        />
                                                        <input
                                                            type="number"
                                                            className="form-control right-input"
                                                            // id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
                                                            value={paymentDetails?.amount}
                                                            // onFocus={(event) => {
                                                            //     if (event.target.value == '0.00' || event.target.value == '0') {
                                                            //         // setAmountToPay('');
                                                            //         setPaymentDetails({ ...paymentDetails, amount: '' });
                                                            //     }
                                                            //     event.target.select();
                                                            // }}
                                                            onChange={(e) => { 
                                                                handleChangeAmount(e?.target?.value);
                                                                // setPaymentDetails({ ...paymentDetails, amount: e.target.value });
                                                            }}
                                                            autoComplete={'off'}
                                                            autoFocus
                                                            onClick={(e) => {
                                                                if (e.target.value === '0' || e.target.value === '0.00') {
                                                                    setPaymentDetails({ ...paymentDetails, amount: '' });
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                if (e.target.value === '') {
                                                                    setPaymentDetails({ ...paymentDetails, amount: '0.00' });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <InputGroup className="d-flex">
                                                        <DropdownButton
                                                            variant="outline-secondary"
                                                            title={defaultCurrency}
                                                            id="input-group-dropdown-1"
                                                        >
                                                            {
                                                                currencies.map((currency) => { 
                                                                    return (
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            key={currency.value}
                                                                            onClick={() => { 
                                                                                handleChangeCurrency(currency)
                                                                            }}
                                                                        >
                                                                            {currency.label}
                                                                        </Dropdown.Item>
                                                                    )
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            // id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
                                                            value={paymentDetails?.amount}
                                                            // onFocus={(event) => {
                                                            //     if (event.target.value == '0.00' || event.target.value == '0') {
                                                            //         // setAmountToPay('');
                                                            //         setPaymentDetails({ ...paymentDetails, amount: '' });
                                                            //     }
                                                            //     event.target.select();
                                                            // }}
                                                            onChange={(e) => { 
                                                                handleChangeAmount(e?.target?.value);
                                                                // setPaymentDetails({ ...paymentDetails, amount: e.target.value });
                                                            }}
                                                            autoComplete={'off'}
                                                            autoFocus
                                                            onClick={(e) => {
                                                                if (e.target.value === '0' || e.target.value === '0.00') {
                                                                    setPaymentDetails({ ...paymentDetails, amount: '' });
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                if (e.target.value === '') {
                                                                    setPaymentDetails({ ...paymentDetails, amount: '0.00' });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="paymentAmount">Open ({currencies?.filter((item) => item.value === paymentDetails?.currency)[0].symbol })</label>
                                    <input type="text" className="form-control" value={numberFormatter.format(remaningBalance)} readOnly />
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="paymentAmount">Note</label>
                              
                                    <MentionInput
                                        onContentChange={(e, x, y) => {
                                            setNoteInputValue(JSON.stringify(e));
                                            setNoteInputPlain(x);
                                            setNoteMentions(y);
                                            setPaymentDetails({ ...paymentDetails, note: x });
                                        }}
                                        clear={clearInput}
                                        inputFocus={inputFocus}
                                        setInputFocus={(e) => {
                                            setInputFocus(e);
                                        }}
                                        triggerSave={() => {
                                            // if(noteInputPlain.length > 0) {
                                            //     // addNote && addNote(noteInputValue, selectedNoteType, noteMentions, noteInputPlain);
                                            //     // clearInputField();
                                            // }
                                        }}
                                        enableMention={false}
                                    />
                                </div>
                                <div className="form-group col-12">
                                    
                                    
                                    <div style={{ marginTop: '20px' }}>
                                        {
                                            hasAttachment ? (
                                                <>
                                                     <div className="payment-modal-attachment-container">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div className="col-12" style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div className="payment-modal-attachment-icon">
                                                                            <FaPaperclip />
                                                                        </div>
                                                                        <div className="payment-modal-attachment-name">
                                                                            <div>{uploadedFileName}</div>
                                                                            <div>{uploadedFileSize}</div>
                                                                            
                                                                        </div>
                                                                        <div 
                                                                            className="payment-modal-attachment-delete"
                                                                            onClick={() => {
                                                                                handleRemoveAttachment()
                                                                            }}
                                                                        >
                                                                            <svg id="Component_127_1" height="12" width="12" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                                                                                <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        isAttachmentLoading && (
                                                                            <div className="col-12">
                                                                                <div className="animated-progress-bar"></div>
                                                                            </div>

                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div {...getPaymentRootProps()}>
            
                                                        <div
                                                            className="add-item-empty-div"
                                                        >
                                                            <button type="button" className="btn btn-primary btn-outlined-main-primary dragText" >
                                                                <FaPlus />
                                                                Add File here
                                                            </button>
                                                        </div>
                                                        <input {...getPaymentInputProps({ disabled: vatValidationAlert !== true })} style={{ display: 'none' }} />{' '}
                                                    </div>
                                                </>
                                            )
                                        }
                                       
                                        
                                    </div>

                                </div>
                            </div>


                            <div className="btnarea" style={{ justifyContent: 'right', marginTop: '15px' }}>
                                <div className="">

                                    <div className="row">
                                        <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                            <button onClick={handleClose} className="btn btn-main-danger btn-outlined-danger" style={{ marginRight: '10px' }}>
                                                Close
                                            </button>
                                            <button
                                                className="btn btn-primary btn-outlined-main"
                                                type="submit"
                                                disabled={disableStatus}
                                                
                                                style={{ marginLeft: '4px' }}
                                                onClick={() => { handleSubmitForm(); }}
                                            >
                                                <FaSave /> Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PaymentModal;
