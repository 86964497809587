import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaMinus, FaSave, FaTimes, FaChevronRight } from 'react-icons/fa';
import FileUpload from 'ui-component/FileUpload';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import Select from 'react-select';
import { toast } from 'react-hot-toast';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';



function PaymentFormModal({ showPaymentModal, editPayment, handleClose, onFormSubmit, estimate, defaultPaymentType, selectedPaymentDetails, source }) {
    const [disableStatus, setDisablestatus] = useState(false);
    const [imageId, setImageId] = useState(null);
    const [files, setFiles] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [selectedCreditNoteId, setSelectedCreditNoteId] = useState(null);
    const [transitCompanyCreditNotes, setTransitCompanyCreditNotes] = useState([]);
    const [remainingBalance, setRemainingBalance] = useState(0);
    const [remainingCredit, setRemainingCredit] = useState(0);
    const [amountToPay, setAmountToPay] = useState(0);
    const [allowInvoiceSettlement, setAllowInvoiceSettlement] = useState(false);
    const [optionSelected, setOptionSelected] = useState(false);
    const [totalOpen, setTotalOpen] = useState(0);
    const [convertedTotalOpen, setConvertedTotalOpen] = useState(0);
    const [defaultCurrency, setDefaultCurrency] = useState('USD($)');
    const [selectedCurrency, setSelectedCurrency] = useState('');

    const bankPaymentTypes = [
        { label: 'Bank Transfer', value: 'Bank Transfer' },
        { label: 'Bank Charges', value: 'Bank Charges' }
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch
    } = useForm();

    const handlePayFileOnChange = (e) => {
        if (e.target.files) {
            setPaymentFile(e.target.files[0]);
        }
    };

    const currencies = [
        { label: 'USD($)', value: 'usd' },
        { label: 'EUR(€)', value: 'eur' },
        { label: 'AED(AED)', value: 'aed' }
    ];     

    const formatOptionLabel = ({ label, value }) => (
        <div dangerouslySetInnerHTML={{ __html: label }} />
    );

    useEffect(() => {
        if (estimate?.customer?.credit_notes?.length > 0) {

            estimate?.customer?.credit_notes?.map((credit) => {
                if (credit?.remaining_credit > 0) {
                    setAllowInvoiceSettlement(true);
                    setTransitCompanyCreditNotes((prev) => [
                        ...prev,
                        {
                            label: `<div class="font500">🔴 Credit Note ${credit?.invoice_number} ${credit?.customer?.name
                                ? ('<span style="margin-left: 5px; margin-right: 5px">•</span> ' + credit?.customer?.name + '')
                                : ''} <span style="margin-left: 5px; margin-right: 5px">•</span> ${estimate?.customer?.name}</div> <div>${credit?.start_date} <span style="margin-left: 5px; margin-right: 5px">•</span> ${credit?.currency} ${credit?.remaining_credit}</div><div>${credit?.estimation_details?.map((item => item.details)).join(', ')}</div>`,
                            value: credit?.id,
                            remainingCredit: credit?.remaining_credit
                        }
                    ]);
                }
            });
        }
        // setTransitCompanyCreditNotes(estimate?.customer?.credit_notes || [])
        reset({
            // paymentType: editPayment?.pay_type || 'Bank Transfer',
            paymentAmount: editPayment?.amount_paid || 0,
            paymentDate: editPayment?.payment_date ? moment(editPayment?.payment_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        });
        setImageId(editPayment?.payment_attachment?.id || null);
        setTotalOpen(estimate?.open_balance?.open);
        setConvertedTotalOpen(estimate?.converted_currency_open_balance[estimate?.currency])

        if (estimate?.currency === 'eur') {
            setDefaultCurrency('EUR(€)');
        } else if (estimate?.currency === 'usd') {
            setDefaultCurrency('USD($)');
        } else if (estimate?.currency === 'aed') {
            setDefaultCurrency('AED(AED)');
        } else {
            setDefaultCurrency('USD($)');
        }
        setSelectedCurrency(estimate?.currency);
        

    }, [showPaymentModal, editPayment]);

    useEffect(() => {
        const subscription = watch((value, { name, type, currency }) => {
            console.log('dito yan', selectedCurrency)
            if (name == 'paymentAmount' && type == 'change') {
                calculateNewOpen(value?.paymentAmount, selectedCurrency);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, selectedCurrency]);

    useEffect(() => {
        if (defaultPaymentType != null) {
            setSelectedPaymentType(defaultPaymentType)
        } else {
            setSelectedPaymentType('')
        }
        if (selectedPaymentDetails != null) {
            setAmountToPay(selectedPaymentDetails?.amount_paid);
            setTotalOpen(Number(estimate?.open_balance?.open) + Number(selectedPaymentDetails?.amount_paid));
            setConvertedTotalOpen(Number(estimate?.converted_currency_open_balance[selectedCurrency]) + Number(selectedPaymentDetails?.amount_paid));
        } else {
            setSelectedPaymentType('')
        }
    }, [defaultPaymentType, selectedPaymentDetails])

    const calculateNewOpen = (paid, currency) => {
        setAmountToPay(paid)
        if (editPayment) {
            setTotalOpen(Number(estimate?.open_balance?.open) + Number(editPayment?.amount_paid) - Number(paid));
            setConvertedTotalOpen(Number(estimate?.converted_currency_open_balance[currency]) + Number(editPayment?.amount_paid) - Number(paid));
        } else {
            setTotalOpen(Number(estimate?.open_balance?.open) - Number(paid));
            setConvertedTotalOpen(Number(estimate?.converted_currency_open_balance[currency]) - Number(paid));
        }
    };

    const handleOnSubmit = (data) => {
        if (selectedPaymentType == 'Credit Note' && !selectedCreditNoteId) {
            toast.error('Invoice is Required');
            return false;
        }
        onFormSubmit &&
            onFormSubmit({
                payment_type: selectedPaymentType,
                credit_note_id: selectedCreditNoteId,
                payment_date: data?.paymentDate,
                amount_paid: data?.paymentAmount,
                currency: selectedCurrency,
                estimation_attachment_id: imageId,
                payment_id: editPayment?.id || undefined
            });
    };

    const handleFileUpload = (e) => {
        const files = e.target.files;
        setFiles(files);
        setDisablestatus(true);
        if (files && files.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('payment_file', files[i]);
            }

            formData.append('sales_order_id', estimate?.id);
            api.addSalesOrderPaymentFile(formData).then((res) => {
                setDisablestatus(false);
                setImageId(res.data);
            });
        }
    };

    const handleChangeCurrency = (currency) => {
        setConvertedTotalOpen(estimate?.converted_currency_open_balance[currency?.value])
        setDefaultCurrency(currency.label);
        setSelectedCurrency(currency?.value);
    }
    
    return (
        <Modal show={showPaymentModal} onHide={handleClose} centered size="lg">
            <Modal.Body>
                <div className="modal-card-padding" style={{ padding: '20px' }}>
                    <form onSubmit={handleSubmit(handleOnSubmit)}>
                        {
                            !selectedPaymentType ? (
                                <>
                                    <div className="payment-step-1">

                                        <div className="" style={{ marginBottom: '20px' }}>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-center">
                                                        <h2 className="h4 mb-0 font-weight-bold">How was the invoice paid?</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: '15px' }}>
                                                <div className="col-12 text-center">
                                                    You have indicated that you want to register a payment for {source === 'invoice' ? 'invoice ' : 'sales order '} {' '}
                                                    <span className='text-primary font500'>{estimate?.invoice_number ?? estimate?.estimation_number}</span>.{' '}
                                                    Please indicate below how this invoice was paid.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '15px' }}>
                                            <div className="col-12 text-center">
                                                <div className="card modal-card">
                                                    <div className="row">
                                                        <div className="col-12 card-body-hoverable"
                                                            onClick={() => {
                                                                setSelectedPaymentType('Bank Transfer');
                                                            }}
                                                        >
                                                            <div className="card-body" style={{ borderBottom: '1px solid #eaeaea' }}>
                                                                <div className="row">
                                                                    <div className="col-11 text-left">
                                                                        <div className="font500 card-body-hoverable-text">Bank Transfer</div>
                                                                        {/* <div className="textGray ">Link your bank transaction</div> */}
                                                                    </div>
                                                                    <div className="col-1 cardbody-chevron-container" >
                                                                        <FaChevronRight className="card-body-hoverable-text" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className={allowInvoiceSettlement ? 'col-12 card-body-hoverable' : 'col-12 card-body-hoverable disabled'}
                                                            onClick={() => {
                                                                setSelectedPaymentType('Credit Note');
                                                            }}
                                                        >
                                                            <div className={allowInvoiceSettlement ? 'card-body' : 'card-body disabled'}
                                                                style={{ borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}
                                                            >
                                                                <div className="row">
                                                                    <div className="col-11 text-left">
                                                                        <div className="font500 card-body-hoverable-text">Invoice Settlement</div>

                                                                    </div>
                                                                    <div className="col-1 cardbody-chevron-container" >
                                                                        <FaChevronRight className="card-body-hoverable-text" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>

                                    <div className="payment-step-2">
                                        <div className="" style={{ marginBottom: '20px' }}>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-center">
                                                        <h2 className="h4 mb-0 font-weight-bold">Register payment</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: '15px' }}>
                                                <div className="col-12 text-center">
                                                    You are going to settle an amount for {source === 'invoice' ? 'Invoice ' : 'Sales Order '}
                                                    <span className='text-primary font500'>{estimate?.invoice_number ?? estimate?.estimation_number}</span>.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            {
                                                selectedPaymentType === 'Credit Note' ? (
                                                    <>

                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="paymentType">Invoice</label>

                                                            <Select
                                                                className="rs-container"
                                                                classNamePrefix="rs"
                                                                isSearchable={true}
                                                                // name="invoice"
                                                                options={transitCompanyCreditNotes}
                                                                placeholder="Select Credit Note"
                                                                formatOptionLabel={formatOptionLabel}
                                                                onChange={(selected) => {
                                                                    setSelectedCreditNoteId(selected.value)

                                                                    setRemainingBalance(selected.remainingCredit);
                                                                    setRemainingCredit(0)
                                                                    setOptionSelected(true);
                                                                }}
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        height: optionSelected && '77px',
                                                                    }),
                                                                }}
                                                            // {...register('invoice', { required: true })}
                                                            />

                                                            {errors.invoice && <p className="mt-1 text-danger">Invoice is required</p>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="paymentType">Payment Type</label>

                                                            <input type="text" className="form-control" value={selectedPaymentType} id="paymentType" readOnly />

                                                            {errors.paymentType && <p className="mt-1 text-danger">Payment Type is required</p>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="paymentAmount">Date</label>
                                                            <input type="date" className="form-control" id="paymentDate" {...register('paymentDate', { required: true })} />
                                                            {errors.paymentDate && <p className="mt-1 text-danger">Invalid Date</p>}
                                                        </div>
                                                            <div className="form-group col-md-6">
                                                                
                                                            <label htmlFor="paymentAmount">Amount ({getCurrencySymbol(estimate?.currency)})</label>
                                                            <input type="number"
                                                                className="form-control"
                                                                id="paymentAmount"
                                                                {...register('paymentAmount', { required: true, min: 1 })}
                                                                value={remainingCredit}
                                                                onChange={(e) => {
                                                                    if (Number(e.target.value) <= remainingBalance) {
                                                                        setRemainingCredit(e.target.value);
                                                                    }
                                                                }}
                                                                onFocus={(event) => {
                                                                    if (event.target.value == '0.00' || event.target.value == '0') {
                                                                        setRemainingCredit('');
                                                                    }
                                                                    event.target.select();
                                                                }}
                                                            />
                                                            {errors.paymentAmount && <p className="mt-1 text-danger">Invalid Amount</p>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="paymentAmount">Open ({getCurrencySymbol(estimate?.currency)})</label>
                                                            <input type="text" className="form-control" value={numberFormatter.format(totalOpen)} readOnly />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="paymentType">Payment Type</label>
                                                            {/* <input type="text" className="form-control" value={selectedPaymentType} id="paymentType" readOnly /> */}

                                                            <Select
                                                                menuPosition={'fixed'}
                                                                options={bankPaymentTypes}
                                                                value={bankPaymentTypes?.find((y) => y?.value == selectedPaymentType)}
                                                                className="rs-container"
                                                                classNamePrefix="rs"
                                                                placeholder=""
                                                                onChange={(e) => {
                                                                    setSelectedPaymentType(e?.value);
                                                                }}
                                                            />

                                                            {errors.paymentType && <p className="mt-1 text-danger">Payment Type is required</p>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="paymentAmount">Date</label>
                                                            <input type="date" className="form-control" id="paymentDate" {...register('paymentDate', { required: true })} />
                                                            {errors.paymentDate && <p className="mt-1 text-danger">Invalid Date</p>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="paymentAmount">Amount </label>
                                                            <div className="custom-input-group payment-input-group">
                                                                <InputGroup className="mb-3 d-flex">
                                                                    <DropdownButton
                                                                        variant="outline-secondary"
                                                                        title={defaultCurrency}
                                                                        id="input-group-dropdown-1"
                                                                    >
                                                                        {
                                                                            currencies.map((currency) => { 
                                                                                return (
                                                                                    <Dropdown.Item
                                                                                        href="#"
                                                                                        key={currency.value}
                                                                                        onClick={() => { 
                                                                                            handleChangeCurrency(currency)
                                                                                        }}
                                                                                    >
                                                                                        {currency.label}
                                                                                    </Dropdown.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </DropdownButton>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
                                                                        value={amountToPay}
                                                                        onFocus={(event) => {
                                                                            if (event.target.value == '0.00' || event.target.value == '0') {
                                                                                setAmountToPay('');
                                                                            }
                                                                            event.target.select();
                                                                        }}
                                                                        autoComplete={'off'}
                                                                        autoFocus
                                                                    />
                                                                </InputGroup>
                                                            </div>
                                                            
                                                            {/* <input
                                                                type="number"
                                                                className="form-control"
                                                                id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
                                                                value={amountToPay}
                                                                onFocus={(event) => {
                                                                    if (event.target.value == '0.00' || event.target.value == '0') {
                                                                        setAmountToPay('');
                                                                    }
                                                                    event.target.select();
                                                                }}
                                                            /> */}
                                                            {errors.paymentAmount && <p className="mt-1 text-danger">Invalid Amount</p>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="paymentAmount">Open ({getCurrencySymbol(selectedCurrency)})</label>
                                                            {/* <input type="text" className="form-control" value={numberFormatter.format(totalOpen)} readOnly /> */}
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                // value={numberFormatter.format(totalOpen)}
                                                                value={numberFormatter.format(convertedTotalOpen || 0)}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="shipmentTracking">Attachment</label>
                                                            <FileUpload
                                                                accept=".png,.jpg,.jpeg,.pdf"
                                                                loading={disableStatus}
                                                                onChange={(e) => {
                                                                    handleFileUpload(e);
                                                                }}
                                                                selectedValue={editPayment?.payment_attachment}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>

                                </>
                            )
                        }
                        <div className="row" style={{ marginTop: '15px' }}>
                            <div className="col-md-8 col-12">
                                <nav className="d-none d-sm-block">
                                    <ol class="cd-breadcrumb triangle">
                                        <li className={!selectedPaymentType && ('current')} style={{
                                            borderLeft: '1px solid #eaeaea',
                                        }}>
                                            <a href="javascript:void(0)"
                                                className="font14"
                                                style={{
                                                    textDecoration: 'none',
                                                    borderTop: '1px solid #eaeaea',
                                                    borderBottom: '1px solid #eaeaea',
                                                }}
                                                onClick={() => {
                                                    if (defaultPaymentType == null) {
                                                        setSelectedPaymentType(null);

                                                        setOptionSelected(false);
                                                    }
                                                }}
                                            >
                                                Choose payment method
                                            </a>
                                        </li>
                                        <li className={selectedPaymentType && ('current')}>
                                            <a href="javascript:void(0)" className="font14" style={{ textDecoration: 'none' }}>
                                                Register payment
                                            </a>
                                        </li>

                                    </ol>
                                </nav>
                            </div>
                            <div className="col-md-4 col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <button
                                    className="btn btn-primary btn-outlined-main"
                                    type="submit"
                                    disabled={disableStatus}
                                    style={{ marginRight: '10px' }}
                                >
                                    <FaSave /> {editPayment ? 'Update' : 'Save'}
                                </button>
                                <button className="btn btn-primary btn-outlined-main" onClick={handleClose}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PaymentFormModal;
