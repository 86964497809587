const dev = {
	apps: [
		{
			pusher: {
				key: '046c49c2dedd4a51d490'
			},
			app_urls: ['http://localhost:3000'],
			api_url: 'http://127.0.0.1:8000',
			// api_url: 'https://api.projectmix.eu',
			app_name: 'mixglobal',
			client_name: 'Mix Global'
		}
	]
};

const staging = {
	apps: [
		{
			pusher: {
				key: '046c49c2dedd4a51d490'
			},
			app_urls: ['http://192.168.1.14:3000'],
			api_url: 'http://192.168.1.14:8000',
			app_name: 'mixglobal',
			client_name: 'Mix Global'
		}
	]
};
const prod = {
	apps: [
		{
			pusher: {
				key: 'd31f11893093d99d05dc'
			},
			app_urls: ['https://www.mg.projectmix.ai', 'https://mg.projectmix.ai'],
			api_url: 'https://mgapi.projectmix.ai',
			app_name: 'mixglobal',
			client_name: 'Mix Global'
		},
		{
			pusher: {
				key: '877f68a6f9f09ee106e1'
			},
			app_urls: ['https://www.glp.projectmix.eu', 'http://www.glp.projectmix.eu', 'https://glp.projectmix.eu', 'http://glp.projectmix.eu'],
			api_url: 'https://glpapi.projectmix.eu',
			app_name: 'glpwireless',
			client_name: 'GLP Wireless'
		},
		{
			pusher: {
				key: 'd31f11893093d99d05dc'
			},
			app_urls: ['https://www.ut.projectmix.ai', 'https://ut.projectmix.ai'],
			api_url: 'https://utapi.projectmix.ai',
			app_name: 'usedtrading',
			client_name: 'Used Trading'
		},
		{
			pusher: {
				key: 'd31f11893093d99d05dc'
			},
			app_urls: ['https://www.proton.projectmix.ai', 'https://proton.projectmix.ai'],
			api_url: 'https://protonapi.projectmix.ai',
			app_name: 'protonelectronics',
			client_name: 'Proton Electronics'
		},
		{
			pusher: {
				key: 'd31f11893093d99d05dc'
			},
			app_urls: ['https://www.mgapp.projectmix.ai', 'https://mgapp.projectmix.ai'],
			api_url: 'https://mgappapi.projectmix.ai',
			app_name: 'mixglobal',
			client_name: 'Mix Global'
		},
		{
			pusher: {
				key: 'd31f11893093d99d05dc'
			},
			app_urls: ['https://www.console.projectmix.ai', 'https://console.projectmix.ai'],
			api_url: 'https://consoleapi.projectmix.ai',
			app_name: 'mixglobal',
			client_name: 'Projectmix Console'
		},
	]
};

const config = {
	// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
	// like '/projectmix-material-react/react/default'
	basename: '',
	defaultPath: '/dashboard',
	fontFamily: "'Roboto', sans-serif",
	borderRadius: 12,
	...(process.env.REACT_APP_STAGE === 'prod' ? prod : process.env.REACT_APP_STAGE === 'staging' ? staging : dev)
};

export default config;
